import api from "../httpClient.js";

export async function getDados(id) {
  const response = await api.get(`/feedback/${id}`);
  return response.data;
}

export async function solicitaFeedback(id) {
  const response = await api.get(`/feedback/${id}/send`);
  return response;
}

export async function validaDocumento(cliente_id, documento) {
  const response = await api.get(
    `/feedback/validate?cliente_id=${cliente_id}&documento=${documento}`
  );
  return response;
}

export function putFeedback(id, feedback) {
  let body = {};
  for (let key in feedback) {
    body[key] = feedback[key];
  }
  return api.put(`/feedback/${id}`, body);
}
