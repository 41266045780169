<template>
  <v-app>
    <v-main class="fill-height" v-if="!lodash.isEmpty(evento)">
      <v-container class="fill-height justify-center align-center" fluid>
        <v-card
          class="pa-4 lightgreen d-flex flex-column justify-space-between"
          width="100%"
          max-width="420px"
          height="500px"
        >
          <div class="d-flex justify-center">
            <v-img src="../../assets/logo_branca.png" max-width="150px"></v-img>
          </div>
          <v-window v-model="step" class="mt-12 mb-12">
            <v-window-item :value="1">
              <v-card height="150px">
                <v-card-title class="body-2 font-weight-bold">
                  <div
                    class="text-center"
                    style="max-width: 300px; word-break: keep-all;"
                  >
                    {{ evento.frase1 }}
                    <span class="caption"> ({{ slicedDocumento }})</span>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="confirmaDocumento"
                    label="Documento"
                    :error-messages="errorDoc"
                    :maxlength="4"
                    outlined
                    dense
                    type="tel"
                    class="mt-4"
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-window-item>

            <v-window-item :value="2">
              <v-card outlined height="150px">
                <v-card-title class="body-2 font-weight-bold text-center">
                  {{ evento.frase2 }}
                </v-card-title>
                <v-card-text>
                  <v-card flat class="mt-4">
                    <v-btn-toggle
                      style="width: calc(100% / 5);"
                      v-model="satisfacao"
                      color="toolbar"
                    >
                      <v-btn block x-large icon :value="1">
                        <v-icon color="red accent-4" x-large
                          >sentiment_very_dissatisfied</v-icon
                        >
                      </v-btn>
                      <v-btn block x-large icon :value="2">
                        <v-icon color="orange darken-4" x-large>
                          sentiment_dissatisfied
                        </v-icon>
                      </v-btn>
                      <v-btn block x-large icon :value="3">
                        <v-icon color="amber darken-3" x-large>
                          sentiment_satisfied
                        </v-icon>
                      </v-btn>
                      <v-btn block x-large icon :value="4">
                        <v-icon color="green darken-2" x-large
                          >sentiment_very_satisfied</v-icon
                        >
                      </v-btn>
                      <v-btn block x-large icon :value="5">
                        <v-icon color="error" x-large>favorite</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-window-item>

            <v-window-item :value="3">
              <v-card outlined height="150px">
                <v-card-title class="body-2 font-weight-bold text-center">
                  {{ evento.frase3 }}
                </v-card-title>
                <v-card-text>
                  <v-textarea
                    v-model="obs"
                    placeholder="Observação"
                    append-outer-icon="send"
                    @click:append-outer="sendObs"
                    hide-details
                    dense
                    outlined
                    :rows="2"
                    no-resize
                    class="mt-4 curso-grade"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </v-window-item>

            <v-window-item :value="4">
              <div class="display-2 white--text text-center">
                Gracias!
              </div>
            </v-window-item>
          </v-window>

          <div class="mt-8 white--text subtitle-1 text-center">
            Gracias por contar con nuestro servicio!
          </div>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {
  getDados,
  validaDocumento,
  putFeedback,
} from "@/services/api/feedback.api.js";

export default {
  name: "Feedback",
  props: {
    eventoId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      confirmaDocumento: "",
      errorDoc: null,
      satisfacao: null,
      obs: "",
      evento: {},
      feedback: {},
      loading: false,
    };
  },
  computed: {
    documento() {
      let documento = "";
      if (this.evento) {
        documento = this.evento.documento.split(" ")[0];
      }
      return documento;
    },
    slicedDocumento() {
      let sliced = this.documento.slice(4);
      let masked = sliced.padStart(this.documento.length, "*");
      return masked;
    },
    quatroDigitos() {
      return this.documento.slice(0, -this.documento.length + 4);
    },
    validation(value) {
      return (value) =>
        (value.length === 4 && value === this.quatroDigitos) || "Numero errado";
    },
  },
  watch: {
    confirmaDocumento() {
      if (this.confirmaDocumento.length === 4) {
        this.validate();
      } else {
        this.errorDoc = null;
      }
    },
    satisfacao() {
      if (this.satisfacao) {
        this.feedback.feedback_reacao = this.satisfacao;
        this.updateFeedback();
      }
    },
    obs() {
      this.feedback.feedback_comentario = this.obs;
    },
  },
  methods: {
    fetchDados() {
      getDados(this.eventoId)
        .then((response) => {
          this.evento = { ...response.evento[0], ...response.feedback };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate() {
      this.loading = true;
      validaDocumento(this.evento.cliente_id, this.confirmaDocumento)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.retorno.length) {
              this.step = 2;
              this.errorDoc = null;
            } else {
              this.errorDoc = "Numero nao bate";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateFeedback() {
      this.loading = true;
      this.$Progress.start();
      putFeedback(this.eventoId, this.feedback)
        .then((response) => {
          if (response.status === 201) {
            this.step = this.step + 1;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendObs() {
      if (this.feedback.feedback_comentario.length > 1) {
        this.updateFeedback();
      }
    },
  },
  mounted() {
    this.fetchDados();
  },
};
</script>

<style lang="scss" scoped>
.feedback-bg {
  background-color: #eeeeee !important;
  background-image: url("../../assets/feedback_bg.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

::v-deep .curso-grade textarea {
  margin-top: 0px !important;
}

::v-deep .curso-grade textarea::-webkit-scrollbar {
  width: 10px;
}

::v-deep .curso-grade textarea::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 8px;
  border: 3px solid #14525e;
}

::v-deep .curso-grade textarea::-webkit-scrollbar-track {
  background: #14525e;
  border-radius: 0 4px 4px 0;
}
</style>
